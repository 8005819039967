import React from "react";
import Layout from "../components/Layout";
import { Typography } from "@material-ui/core";

function Thanks() {
  return (
    <Layout>
      <main className="error-page">
        <div className="error-container">
          <Typography align="center" component="h2" variant="h4">
            Nous avons bien reçu votre demmande {" "}
          </Typography>
          <Typography>
            Nous reviendrons vers vous dans un délai de 1 à 3 jours {" "}
          </Typography>

          <Typography>
              Pour tout complément d'informations n'hesitez pas à nous contacter au 0556 12 65 93
            {" "}
          </Typography>
        </div>
      </main>
    </Layout>
  );
}

export default Thanks;
